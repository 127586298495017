import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from '../../services/toast.service';
import { TransactionsService } from '../../services/transactions.service';
import { hideEmail } from '../../utility/utility';
import * as CryptoJS from 'crypto-js';
import { ToastType } from '../toast-dialog/toast-dialog.component';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-verify-otp-popup',
  templateUrl: './verify-otp-popup.component.html',
  styleUrls: ['./verify-otp-popup.component.scss'],
})
export class VerifyOtpPopupComponent implements OnInit {
  otpForm!: FormGroup;

  constructor(
    private _fb: FormBuilder,
    public dialogRef: MatDialogRef<VerifyOtpPopupComponent>,
    private toast: ToastService,
    private transactionsService: TransactionsService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // console.log('VerifyOtpPopupComponent data => ', this.data);
  }

  ngOnInit(): void {
    this.otpForm = this._fb.group({
      otp1: ['', [Validators.required]],
      otp2: ['', [Validators.required]],
      otp3: ['', [Validators.required]],
      otp4: ['', [Validators.required]],
      otp5: ['', [Validators.required]],
      otp6: ['', [Validators.required]],
    });
    if (this.data.signature.participantEmailId)
      this.data.signature.hideEmail = hideEmail(this.data.signature.participantEmailId);
  }

  resendOtp() {
    let payload: any = {
      transactionId: this.data.signature.transactionId
        ? Number(this.data.signature.transactionId)
        : null,
      esigner: 'PARTICIPANT',
      featureName: 'ESIGN_METHOD_EMAIL_OTP',
      documentParticipantPropertyId: this.data.documentParticipantPropertyId
    };
    this.transactionsService.sendOTP(payload).subscribe({
      next: (response: any) => {
        // console.log(response);
        this.toast.openToast(
          'You have successfully resent OTP!',
          ToastType.success
        );
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }

  verifyOtp() {
    if (this.otpForm.valid) {
      let value: any = this.otpForm.value;
      let arrayValue: any = Object.values(value);
      let otpValue = arrayValue.join('');
      let payload: any = {
        transactionId: this.data.signature.transactionId
          ? String(this.data.signature.transactionId)
          : null,
        esigner: 'PARTICIPANT',
        featureName: 'ESIGN_METHOD_EMAIL_OTP',
        value: CryptoJS.AES.encrypt(otpValue, environment.ENCRYPTION_URL).toString(),
        documentId: this.data.signature.documentId ? Number(this.data.signature.documentId) : null,
        documentParticipantPropertyId: this.data.documentParticipantPropertyId,
      };
      this.transactionsService.confirmOTPAndSelfSign(payload).subscribe({
        next: (response: any) => {
          if (response.message == 'Success') {
            this.toast.openToast(
              'You have successfully verified and signed!',
              ToastType.success
            );
            this.dialogRef.close({ verified: true, data: this.data });
          }
        },
        error: (error: any) => {
          console.log(error);
          if (error.error && error.error.message) {
            this.spinner.hide();
            this.toast.openToast('Invalid OTP', ToastType.error);
          }
        },
      });
    }
  }

  keytab(event: any, maxLength: number) {
    if (event.keyCode == 8) {
      let nextInput = event.srcElement.previousElementSibling;
      if (nextInput == null) return;
      else nextInput.focus();
    } else {
      if (event.target.value) {
        let nextInput = event.srcElement.nextElementSibling;
        if (nextInput == null) return;
        else nextInput.focus();
      }
    }
  }
}
