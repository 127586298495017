import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TransactionsService } from '../../services/transactions.service';
@Component({
  selector: 'app-transaction-details-popup',
  templateUrl: './transaction-details-popup.component.html',
  styleUrls: ['./transaction-details-popup.component.scss'],
})
export class TransactionDetailsPopupComponent implements OnInit {
  scrHeight: any;
  scrWidth: any = window.innerWidth;
  participants: any;


  constructor(
    public transactionsService: TransactionsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getAllParticipant(this.data)
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // console.log('scrWidth => ', this.scrWidth)
  }

  getAllParticipant(transactionId: string) {
    this.transactionsService.getAllParticipant(transactionId).subscribe({
      next: async (data: any) => {
        this.participants = data.participants;
        // console.log(this.participants);
      },
      error: (error: any) => {
        console.error(error);
      },
    });
  }

}
