<div class="pdf-viewer-modal-parent">
  <div class="modal-close-button">
    <button [mat-dialog-close]="true" class="close-button" mat-icon-button>
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </div>
  <div class="header_pdf relative-modal">
    <div class="left-icon w-30 w-15">
      <img src="assets/icons/pdf_view_header_icon.svg" />
      <label class="header-title">{{ data.name }}</label>
    </div>
    <div *ngIf="isLoaded" class="page-title w-30">
      <svg
        (click)="prevPage()"
        height="30"
        viewBox="0 0 30 30"
        width="30"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g [class.disable-svg]="page === 1" data-name="Group 1595">
          <g data-name="Group 1580">
            <path
              d="M0 0h30v30H0z"
              data-name="Rectangle 292"
              style="fill: none"
              transform="rotate(-90 15 15)"
            />
            <path
              d="M8.232 9.932 6.747 8.415 0 1.517 1.484 0l6.748 6.9L14.979 0l1.484 1.517-6.747 6.9z"
              data-name="Union 8"
              style="fill: #4f4f4f"
              transform="rotate(90 7.002 13.291)"
            />
          </g>
        </g>
      </svg>
      <label>Page {{ page }} / {{ totalPages }}</label>
      <svg
        (click)="nextPage()"
        height="30"
        viewBox="0 0 30 30"
        width="30"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g [class.disable-svg]="page === totalPages" data-name="Group 1594">
          <g data-name="Group 1580">
            <path
              d="M0 0h30v30H0z"
              data-name="Rectangle 292"
              style="fill: none"
              transform="rotate(-90 15 15)"
            />
            <path
              d="M8.232 0 6.747 1.517 0 8.414l1.484 1.518 6.747-6.9 6.747 6.9 1.484-1.517-6.747-6.9z"
              data-name="Union 8"
              style="fill: #4f4f4f"
              transform="rotate(90 6.675 12.964)"
            />
          </g>
        </g>
      </svg>
    </div>
    <div class="w-30 w-300"></div>
  </div>
  <div class="pdf-viewer-parent">
    <div class="mat-container">
      <mat-dialog-content>
        <pdf-viewer
          (after-load-complete)="afterLoadComplete($event)"
          [original-size]="false"
          [page]="page"
          [render-text]="false"
          [show-all]="false"
          [src]="file"
          [zoom-scale]="'page-fit'"
          [zoom]="1"
          class="pdf-preview"
        >
        </pdf-viewer>
      </mat-dialog-content>
    </div>
  </div>
</div>
