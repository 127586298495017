import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastDialogComponent } from '../components/toast-dialog/toast-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private snackBar: MatSnackBar) {}

  openToast(message: string, type: string) {
    let snackBarColor = this.snackBar.openFromComponent(ToastDialogComponent, {
      data: { message, type },
      duration: 4000,
      panelClass: ['snack-notification'],
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
    snackBarColor.onAction().subscribe(() => {
      snackBarColor.dismiss();
    });
  }
}
