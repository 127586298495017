import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

interface ToastDto {
  message: string;
  type: string;
}

export enum ToastType {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info'
}

@Component({
  selector: 'app-toast-dialog',
  templateUrl: './toast-dialog.component.html',
  styleUrls: ['./toast-dialog.component.scss'],
})
export class ToastDialogComponent implements OnInit {
  constructor(
    public sbRef: MatSnackBarRef<ToastDialogComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: ToastDto
  ) { }

  ngOnInit(): void {
    // console.log('ToastDialogComponent data => ', this.data);
  }
}
