import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-steps-ui',
  templateUrl: './steps-ui.component.html',
  styleUrls: ['./steps-ui.component.scss'],
})
export class StepsUiComponent implements OnInit {
  @Input() step: number = 0;
  @Output() changeStepEmit = new EventEmitter<any>();

  @Input() fluid: boolean = false;
  @Output() changeFluidEmit = new EventEmitter<any>();

  @Input() open: boolean = false;

  currentFluid: boolean = true;
  currentStep: number = 0;
  sizePannel: boolean = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['step']) {
      this.currentStep = changes['step'].currentValue;
    }
    if (changes['fluid']) {
      this.currentFluid = changes['fluid'].currentValue;
    }
    if (changes['open']) {
      this.sizePannel = changes['open'].currentValue;
    }
  }

  ngOnInit(): void {
    // console.log('sizePannel => ', this.sizePannel);
  }

  changeStep(step: number) {
    if (this.currentStep > step) {
      this.changeStepEmit.emit({ step: step });
    }
  }

  changeSize() {
    this.sizePannel = !this.sizePannel;
    // console.log('sizePannel => ', this.sizePannel);
    this.changeFluidEmit.emit({ sizePannel: this.sizePannel });
  }
}
