import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { LocalDatastorageRefService } from "../services/local-datastorage-ref.service";


@Injectable({
  providedIn: 'root'
})
export class LocalDatastorageService {

  private _localStorage: Storage;

  private _myUICustomData$ = new BehaviorSubject<any>(null);
  _myUICustomData = this._myUICustomData$.asObservable();

  constructor(private _localDataStorageRefService: LocalDatastorageRefService) {
    this._localStorage = _localDataStorageRefService.localStorage;
  }

  setInfo(data: any): void {
    const jsonData = JSON.stringify(data);
    this._localStorage.setItem("uiCustomData", jsonData);
    this._myUICustomData$.next(data);
  }

  loadInfo(): void {
    const data = JSON.parse(<any>this._localStorage.getItem('uiCustomData') ?? null);
    this._myUICustomData$.next(data);
  }

  clearInfo() {
    this._localStorage.removeItem("myData");
    this._myUICustomData$.next(null);
  }

}
