import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TemplatesService } from '../../services/templates.service';

@Component({
  selector: 'app-preview-pdf-with-signatures',
  templateUrl: './preview-pdf-with-signatures.component.html',
  styleUrls: ['./preview-pdf-with-signatures.component.scss'],
})
export class PreviewPdfWithSignaturesComponent implements OnInit {
  selectedDocument: any = {};
  page: number = 1;
  totalPages: number = 1;
  isLoadedPDF: boolean = false;
  totalPagesArray: any[] = [];;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private templatesService: TemplatesService
  ) { }

  ngOnInit(): void {
    if (!this.selectedDocument.id) {
      this.selectedDocument = JSON.parse(JSON.stringify(this?.data.docType[0]));
      this.getPreviewOfDocument(this.data.docType[0], 0);
      this.getSignaturePositions(this.data.doc)
      if (!this.selectedDocument.src) {
        this.getPreviewOfDocument(this.selectedDocument, 0);
      } else {
        this.isLoadedPDF = true;
      }
    }
  }

  nextPage() {
    if (this.page !== this.totalPages) {
      this.page++;
    }
  }

  prevPage() {
    if (this.page !== 1) {
      this.page--;
    }
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.totalPagesArray = Array.from(
      { length: this.totalPages },
      (_, i) => i + 1
    );
    this.data.docType.find((x: any) => x.id === this.selectedDocument.id).totalPages =
      this.totalPages;
  }

  changeSelectedDocument(i: number) {
    if (this.selectedDocument.id !== this.data.docType[i].id) {
      this.isLoadedPDF = false;
      this.page = 1;
      this.totalPages = 1;
      this.totalPagesArray = [];
      this.selectedDocument = this.data.docType[i];
      if (!this.data.docType[i].src) {
        this.getPreviewOfDocument(this.data.docType[i], i);
        this.getSignaturePositions(this.data.doc)
      } else {
        this.isLoadedPDF = true;
      }
    } else {
      this.selectedDocument = this.data[i];
      if (!this.data.docType[i].src) {
        this.getPreviewOfDocument(this.data.docType[i], i);
        this.getSignaturePositions(this.data.doc)
      } else {
        this.isLoadedPDF = true;
      }
    }
  }

  getPreviewOfDocument(documentType: any, i: number) {
    this.templatesService.getDocumentForPreview(documentType.id).subscribe({
      next: (file) => {
        this.selectedDocument.src = URL.createObjectURL(file);
        this.data.docType[i].src = URL.createObjectURL(file);
        this.isLoadedPDF = true;
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  getSignaturePositions(documentType: any) {
    const allDocuments = [documentType];
    let pdfCanvas = document.querySelector('.pdf-image')
    let yAxisHeight = 550;
    let xAxisWidth = 450;
    if (pdfCanvas) {
      xAxisWidth = pdfCanvas?.clientWidth;
      yAxisHeight = pdfCanvas?.clientHeight;
    }
    this.templatesService.getSignaturePositionInOperation(documentType.documentTypeId, "").subscribe({
      next: (res) => {
        allDocuments.forEach((doc: any, i: any) => {
          let signs = res.signPositions.sort((signA: any, signB: any) => (signA.id < signB.id ? -1 : Number(signA.id > signB.id)));
          let docs = doc.participants.sort((partA: any, partB: any) => (partA.operationsId < partB.operationsId ? -1 : Number(partA.operationsId > partB.operationsId)));
          if (signs && signs.length > 0) {
            docs.forEach((mdoc: any, idoc: number) => {
              signs.forEach((data: any, index: number) => {
                if (docs[idoc].operationsId === signs[index].operationsId) {
                  let positionX = (data.positionX / 100) * xAxisWidth;
                  let positionY = (data.positionY / 100) * yAxisHeight;
                  let label = docs[idoc].name;
                  let eSingpos = { ...data, positionX, positionY, label, }
                  this.selectedDocument.singPositionIdList.push(eSingpos);
                }
              })
            })
          }
        })
      },
      error: (error) => {
        console.error(error);
      },
    });
  }
}
