import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ThemeChangeService } from '../../services/theme-change.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AuthService } from '../../services/auth.service';
import { TemplatesFilterService } from '../../services/templates-filter.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnChanges {
  @Input() fixedToolbar: boolean = true;
  isDarkTheme: boolean = false;
  opened = false;
  isSearch: boolean = false;
  scrHeight: any;
  scrWidth: any = window.innerWidth;
  redirectionUrl: any;
  redirectContent: any;

  constructor(
    private themeService: ThemeChangeService,
    private templatesFilterService: TemplatesFilterService,
    private authService: AuthService
  ) { }

  openNav() {
    this.opened = !this.opened;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fixedToolbar']) {
      this.fixedToolbar = changes['fixedToolbar'].currentValue;
    }
  }

  ngOnInit(): void {
    this.themeService
      .isDarkTheme()
      .subscribe((isDarkTheme) => (this.isDarkTheme = isDarkTheme));
    this.redirectContent = localStorage.getItem('returnPageUrl');
    if (this.redirectContent) {
      this.redirectionUrl = environment.BASE_URL + this.redirectContent;
    } else {
      this.redirectionUrl = environment.BASE_URL;
    }

  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // console.log('HeaderComponent scrWidth => ', this.scrWidth);
  }

  toggleSearchOption(status: boolean) {
    this.isSearch = status;
  }

  toggleDarkTheme($event: MatSlideToggleChange) {
    this.themeService.setDarkTheme($event.checked);
  }

  logout() {
    this.authService.logout();
  }

  searchTemplates(search: string) {
    if (search && search.trim() != '') {
      this.templatesFilterService.setTemplates({ search: search.trim() });
    }
  }
}
