import { Component } from '@angular/core';
import { SE } from './data/se';
import { ThemeChangeService } from './shared/services/theme-change.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'ManchUIFinal';
  className = 'light-theme';
  isDarkTheme: boolean = false;
  // stickey functionality
  fixedToolbar: boolean = true;

  constructor(private themeService: ThemeChangeService) {}

  ngOnInit() {
    this.themeService
      .isDarkTheme()
      .subscribe((isDarkTheme) => (this.isDarkTheme = isDarkTheme));
  }

  public detectScroll(event: SE) {
    if (event.header) {
      this.fixedToolbar = true;
    }
    if (event.bottom) {
      this.fixedToolbar = false;
    }
  }
}
