<div class="main-div">
  <div class="modal-close-button">
    <button [mat-dialog-close]="true" class="close-button" mat-icon-button>
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </div>
  <div class="relative-modal">
    <mat-card class="no-padding-card pb-0">
      <mat-card-content>
        <div class="header_pdf relative-modal">
          <div class="left-icon w-30 w-15 w-33">
            <img src="assets/icons/pdf_view_header_icon.svg" />
            <label class="header-title">{{
              selectedDocument?.displayName
              }}</label>
          </div>
          <div *ngIf="isLoadedPDF" class="page-title w-30 w-46">
            <svg (click)="prevPage()" height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg">
              <g [class.disable-svg]="page === 1" data-name="Group 1595">
                <g data-name="Group 1580">
                  <path d="M0 0h30v30H0z" data-name="Rectangle 292" style="fill: none" transform="rotate(-90 15 15)" />
                  <path d="M8.232 9.932 6.747 8.415 0 1.517 1.484 0l6.748 6.9L14.979 0l1.484 1.517-6.747 6.9z"
                    data-name="Union 8" style="fill: #4f4f4f" transform="rotate(90 7.002 13.291)" />
                </g>
              </g>
            </svg>
            <label>Page {{ page }} / {{ totalPages }}</label>
            <svg (click)="nextPage()" height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg">
              <g [class.disable-svg]="page === totalPages" data-name="Group 1594">
                <g data-name="Group 1580">
                  <path d="M0 0h30v30H0z" data-name="Rectangle 292" style="fill: none" transform="rotate(-90 15 15)" />
                  <path d="M8.232 0 6.747 1.517 0 8.414l1.484 1.518 6.747-6.9 6.747 6.9 1.484-1.517-6.747-6.9z"
                    data-name="Union 8" style="fill: #4f4f4f" transform="rotate(90 6.675 12.964)" />
                </g>
              </g>
            </svg>
          </div>
          <div class="w-30 w-300 w-59">
            <div class="dropdown column-dropdown-wrap fileicon">
              <a #t="matMenuTrigger" [matMenuTriggerFor]="documentMenu" class="column-dropdown cursor-pointer">
                Select Document
                <img class="cursor-pointer cursor-pointer" src="assets/icons/accrodian_down.svg" />
              </a>
            </div>
          </div>
        </div>
        <mat-card class="signatory-pdf-content">
          <div class="pdf-parent-div">
            <ng-container *ngFor="let sign of data?.doc?.singPositionIdList">
              <ng-container *ngIf="
                  sign.page === -1
                    ? page === selectedDocument.totalPages
                    : sign.page === 0
                ">
                <ng-container *ngTemplateOutlet="signatureTemplate; context: { info: sign }"></ng-container>
              </ng-container>
            </ng-container>
            <pdf-viewer (after-load-complete)="afterLoadComplete($event)" *ngIf="isLoadedPDF" [render-text]="false"
              [original-size]="false" [page]="page" [show-all]="false" [src]="selectedDocument.src"
              [zoom-scale]="'page-fit'" [zoom]="1" class="img-fluid pdf-image">
            </pdf-viewer>
          </div>
        </mat-card>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<ng-template #signatureTemplate let-info="info">
  <div [style.left.px]="info.positionX" [style.top.px]="info.positionY" class="signature-div">
    <label>{{ info.label.replace('Signatory','Signature') }}</label>
    <div class="signature-parent">
      <div class="corner corner-top-left"></div>
      <div class="corner corner-top-right"></div>
      <div class="corner corner-bottom-left"></div>
      <div class="corner corner-bottom-right"></div>
    </div>
  </div>
</ng-template>

<mat-menu #documentMenu="matMenu" class="document-menu">
  <button (click)="selectedDocument.id !== document.id && changeSelectedDocument(i)"
    *ngFor="let document of data?.docType; let i = index"
    [class.selected-document]="selectedDocument.id === document.id" mat-menu-item>
    <label>{{ document.displayName }}</label>
  </button>
</mat-menu>