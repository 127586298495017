<div *ngIf="data.type == 'success'" class="toast-main-content" mat-dialog-title>
  <div class="toast-text">
    <img src="assets/icons/check_circle_white.svg" />
    {{ data?.message }}
    <img class="toast-logo" src="/assets/logo/manch_logo_mini.svg" />
  </div>
</div>
<div *ngIf="data.type == 'error'" class="toast-main-content11" mat-dialog-title>
  <div class="toast-text">
    <!-- <img src="assets/icons/check_circle_white.svg" /> -->
    <mat-icon>error</mat-icon>
    {{ data?.message }}
    <img class="toast-logo" src="/assets/logo/manch_logo_mini.svg" />
  </div>
</div>
<div *ngIf="data.type == 'warning'" class="toast-main-content12" mat-dialog-title>
  <div class="toast-text">
    <mat-icon>warning</mat-icon>
    {{ data?.message }}
    <img class="toast-logo" src="/assets/logo/manch_logo_mini.svg" />
  </div>
</div>
<div *ngIf="data.type == 'info'" class="toast-main-content13" mat-dialog-title>
  <div class="toast-text">
    <mat-icon>info</mat-icon>
    {{ data?.message }}
    <img class="toast-logo" src="/assets/logo/manch_logo_mini.svg" />
  </div>
</div>