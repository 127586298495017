<div class="modal-close-button">
  <button [mat-dialog-close]="true" class="close-button" mat-icon-button>
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>

<div class="relative-modal">
  <div class="popup-title">
    <img class="svg-opacity" src="assets/images/file-icon.svg" />
    <h2 mat-dialog-title>About {{ data?.name }}</h2>
  </div>
  <p class="paragraph content" mat-dialog-content>
    {{ data?.description }}
  </p>
</div>