<header *ngIf="scrWidth > 770" [ngClass]="isDarkTheme ? 'dark-theme' : 'light-theme'" class="main-header">
  <nav class="navbar navbar-expand-lg navbar-light fixed-top menu-background">
    <div class="container mobile-head">
      <a *ngIf="fixedToolbar" class="navbar-brand" href="{{redirectionUrl}}">
        <img [src]="
            !isDarkTheme
              ? '/assets/logo/manch_logo_light.svg'
              : '/assets/logo/manch_logo_dark.svg'
          " alt="logo" />
      </a>

      <a *ngIf="!fixedToolbar" class="navbar-brand" href="javascript:void(0)">
        <svg height="34.777" viewBox="0 0 36.25 34.777" width="36.25" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <clipPath id="z7tlb6eeta">
              <path d="M0 0h36.25v34.777H0z" data-name="Rectangle 379" style="fill: none !important" />
            </clipPath>
          </defs>
          <g data-name="Manch Logo">
            <g data-name="Group 1021">
              <g data-name="Group 903" style="clip-path: url(#z7tlb6eeta)">
                <path [style]="!isDarkTheme ? 'fill:#EF002C' : 'fill:#fff'"
                  d="M246.64 17.5c-.03 5.754-.012 11.517-.009 17.274q8.62-8.74 17.228-17.492V-.004q-8.6 8.76-17.221 17.5"
                  data-name="Path 408" transform="translate(-229.438)" />
                <path [style]="!isDarkTheme ? 'fill:#EF002C' : 'fill:#fff'"
                  d="M17.2 19.7Q8.59 10.979 0 2.23V19.7c5.734 0 11.469-.018 17.2.006" data-name="Path 409"
                  transform="translate(0 -2.072)" />
              </g>
            </g>
          </g>
        </svg>
      </a>

      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <!-- search -->
        <li class="nav-item searchBox">
          <mat-icon class="material-icons-outlined">search</mat-icon>
          <input #searchInput (keyup)="searchTemplates(searchInput.value)" class="searchInput" name=""
            placeholder="Search" type="text" />
        </li>
        <!-- notification -->
        <li class="nav-item">
          <mat-icon class="material-icons-outlined">notifications</mat-icon>
        </li>
        <!-- question -->
        <li class="nav-item">
          <mat-icon class="material-icons-help_outline">help_outline</mat-icon>
        </li>
        <!-- profile -->
        <li class="nav-item">
          <mat-icon #t="matMenuTrigger" [class.open-profile-menu]="t.menuOpen" [matMenuTriggerFor]="menu"
            aria-label="Profile Menu" class="material-icons-outlined">
            person
          </mat-icon>
        </li>
      </ul>
    </div>
  </nav>
</header>

<header *ngIf="scrWidth <= 770" [ngClass]="isDarkTheme ? 'dark-theme' : 'light-theme'" class="mobile-header">
  <nav class="navbar navbar-expand-lg navbar-light fixed-top menu-background">
    <div *ngIf="!isSearch" class="container mobile-head">
      <div>
        <a class="navbar-brand menu-bar" href="javascript:void(0)">
          <img #toggleButton (click)="openNav()" [src]="
              !isDarkTheme
                ? '/assets/images/menu_black_24dp.svg'
                : '/assets/images/menu_white_24dp.svg'
            " alt="logo" />
        </a>
        <a *ngIf="fixedToolbar" class="navbar-brand" href="javascript:void(0)">
          <img [src]="
              !isDarkTheme
                ? '/assets/logo/manch_logo_light.svg'
                : '/assets/logo/manch_logo_dark.svg'
            " alt="logo" />
        </a>
        <a *ngIf="!fixedToolbar" class="navbar-brand" href="javascript:void(0)">
          <svg height="34.777" viewBox="0 0 36.25 34.777" width="36.25" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <clipPath id="z7tlb6eeta">
                <path d="M0 0h36.25v34.777H0z" data-name="Rectangle 379" style="fill: none !important" />
              </clipPath>
            </defs>
            <g data-name="Manch Logo">
              <g data-name="Group 1021">
                <g data-name="Group 903" style="clip-path: url(#z7tlb6eeta)">
                  <path [style]="!isDarkTheme ? 'fill:#EF002C' : 'fill:#fff'"
                    d="M246.64 17.5c-.03 5.754-.012 11.517-.009 17.274q8.62-8.74 17.228-17.492V-.004q-8.6 8.76-17.221 17.5"
                    data-name="Path 408" transform="translate(-229.438)" />
                  <path [style]="!isDarkTheme ? 'fill:#EF002C' : 'fill:#fff'"
                    d="M17.2 19.7Q8.59 10.979 0 2.23V19.7c5.734 0 11.469-.018 17.2.006" data-name="Path 409"
                    transform="translate(0 -2.072)" />
                </g>
              </g>
            </g>
          </svg>
        </a>
      </div>
      <div class="searchBox-mobile">
        <a class="navbar-brand pos-rel-top-7" href="javascript:void(0)">
          <mat-icon (click)="toggleSearchOption(true)" class="material-icons-outlined">search
          </mat-icon>
        </a>
      </div>
    </div>

    <div *ngIf="isSearch" class="container mobile-head mobile-head-flex">
      <mat-icon class="material-icons-outlined s-icon-flex">search</mat-icon>
      <input class="search-sidebar" matInput placeholder="Search" />
      <button class="close-button" mat-icon-button>
        <a class="navbar-brand pos-rel-top-7" href="javascript:void(0)">
          <mat-icon (click)="toggleSearchOption(false)" class="material-icons-outlined">close
          </mat-icon>
        </a>
      </button>
    </div>
  </nav>
  <section [ngClass]="{ open: opened, close: !opened }" class="left-panel close">
    <div #menu (outsideClick)="opened = false" class="left-panel-wrapper" outsideClick>
      <div class="left-panel-inside">
        <ul class="ul-left-panel">
          <li class="logo-panel">
            <img src="/assets/logo/Manch_Logo_Mobile.svg" />
          </li>
          <!-- profile -->
          <li class="panel-item">
            <img src="/assets/images/My profile_icon.svg" />
            <p>My Profile</p>
          </li>
          <!-- help -->
          <li class="panel-item">
            <img src="/assets/images/Group_icon.svg" />
            <p>Help</p>
          </li>
          <!-- notification -->
          <li class="panel-item">
            <img src="/assets/images/notificattion_icon.svg" />
            <p>Notification</p>
          </li>
          <!-- Setting -->
          <li class="panel-item">
            <img src="/assets/images/settings_logo.svg" />
            <p>Settings</p>
          </li>
          <!-- Moon -->
          <li class="panel-item">
            <img src="/assets/images/Dark mode.svg" />
            <p>Dark Mode</p>
            <div class="normal-toggle">
              <mat-slide-toggle (change)="toggleDarkTheme($event)" [checked]="isDarkTheme" class="slide-toggle"
                color="success">
              </mat-slide-toggle>
            </div>
          </li>
          <!-- Logout -->
          <li (click)="logout()" class="panel-item">
            <img src="/assets/images/Logout_icon.svg" />
            <p>Log Out</p>
          </li>
        </ul>
      </div>
    </div>
  </section>
</header>

<mat-menu #menu="matMenu" class="profile-menu">
  <button mat-menu-item>
    <img src="assets/icons/profile_icon.svg" />
    <label>My Profile</label>
  </button>
  <button mat-menu-item>
    <img src="assets/icons/settings_icon.svg" />
    <label>Settings</label>
  </button>
  <button mat-menu-item>
    <img src="assets/icons/dark_mode.svg" />
    <mat-slide-toggle (change)="toggleDarkTheme($event)" [checked]="isDarkTheme" class="slide-toggle" color="primary">
      Dark Mode
    </mat-slide-toggle>
  </button>
  <button (click)="logout()" mat-menu-item>
    <img src="assets/icons/logout_icon.svg" />
    <label>Log Out</label>
  </button>
</mat-menu>