<div class="delete-popup">
  <h1 mat-dialog-title>{{ title }}</h1>
  <div mat-dialog-content>
    <p>
      Are you sure you want to delete<span>{{ message }}?</span>
    </p>
  </div>
  <div mat-dialog-actions>
    <button (click)="onConfirm()" color="primary" mat-raised-button>
      Delete
    </button>
    <button (click)="onDismiss()" mat-button>Cancel</button>
  </div>
</div>
