import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { apiEndpoints } from '../utility/api-endpoints';
import { environment } from 'src/environments/environment';

export class CurrentUser {
  email?: string;
  id?: string;
  companyName?: string;
  companyId?: string;
  authToken?: string;
  name?: string;
  role?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentUser: Observable<CurrentUser | undefined>;
  currentUserSubject: BehaviorSubject<CurrentUser | undefined>;

  constructor(private _http: HttpClient, private _router: Router) {
    this.currentUserSubject = new BehaviorSubject(
      JSON.parse(<string>localStorage.getItem('currentUser') ?? null)
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): CurrentUser | undefined {
    return this.currentUserSubject.value;
  }

  public set currentUserValue(user: CurrentUser | undefined) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  public get isAdmin(): boolean {
    return this.currentUserValue?.role?.toLowerCase() === 'admin';
  }

  public login(): Observable<any> {
    return this._http.get<any>(apiEndpoints.getLoginUserInfo).pipe(
      map((res) => {
        if (res) {
          return res;
        }
        throw 'Invalid Data.';
      })
    );
  }

  public logout(returnUrl: string = '') {
    localStorage.clear();
    // this._router.navigate(['auth/login'], returnUrl ? { queryParams: { returnUrl: returnUrl } } : {})
    // this._router.navigate(['auth/login'], returnUrl ? { queryParams: { returnUrl: 'login' } } : {})
    //   .then((r) => {
    //     this.currentUserSubject.next(undefined);
    //   });
    window.location.href = environment.BASE_URL + 'login?l=logout';
  }
}
