import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TemplatesService } from '../../services/templates.service';
import { AuthService } from '../../services/auth.service';
import { ToastService } from '../../services/toast.service';
import { LocalDatastorageService } from '../../services/local-datastorage.service';


@Component({
  selector: 'app-create-template-popup',
  templateUrl: './create-template-popup.component.html',
  styleUrls: ['./create-template-popup.component.scss'],
})
export class CreateTemplatePopupComponent implements OnInit {
  createTemplateForm!: FormGroup;
  currentUser: any = null;
  companySpecificUiCustomData: any = [];

  constructor(
    private _fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateTemplatePopupComponent>,
    private templatesService: TemplatesService,
    private authService: AuthService,
    private toastService: ToastService,
    private localDataStorageService: LocalDatastorageService,
  ) {
    this.currentUser = this.authService.currentUserValue;
  }

  ngOnInit(): void {
    this.createTemplateForm = this._fb.group({
      name: ['', [Validators.required]],
      description: [''],
    });
    this.loadCustomDataInfo();
  }

  loadCustomDataInfo(): void {
    const customUIData = JSON.parse(<any>localStorage.getItem('uiCustomData') ?? null);
    this.companySpecificUiCustomData = customUIData;
  }

  createTemplate() {
    this.templatesService
      .createTemplate(
        {
          name: this.createTemplateForm.controls['name'].value,
          description: this.createTemplateForm.controls['description'].value,
          type: 'GENERIC', // default
          firstPartyAccess: true, //default
          bulkOperation: false, // default
        },
        this.currentUser.companyId
      )
      .subscribe({
        next: (data) => {
          if (data.id) {
            this.toastService.openToast(
              'Template Created.',
              'success'
            );
            this.dialogRef.close(data.id);
          } else {
            this.toastService.openToast(
              'Got error while creating template. Please try again later.',
              'error'
            );
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
  }
}
