import { Injectable } from '@angular/core';
import { apiEndpoints } from '../utility/api-endpoints';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  constructor(private _http: HttpClient) { }

  addTransaction(values: any) {
    return this._http.post<any>(`${apiEndpoints.addTransaction}`, values);
  }

  getTransactionDocumentsDetails(id: string, participantId?: string) {
    return this._http.get<any>(
      `${apiEndpoints.getTransactionDocumentsDetails.replace('[[txnId]]', id)
        .replace('[[participantId]]', participantId ? participantId : '')
      }`
    );
  }

  getTransactionDetails(transactionId: string) {
    return this._http.get<any>(
      `${apiEndpoints.getTransactionDetails.replace(
        '[[transactionId]]',
        transactionId
      )}`
    );
  }

  uploadDocumentInTransaction(files: any, id: string) {
    return this._http.post<any>(
      `${apiEndpoints.uploadDocumentInTransaction.replace('[[txnId]]', id)}`,
      files
    );
  }

  getFile(storageId: string) {
    return this._http.get(
      `${apiEndpoints.getFile.replace('[[storageId]]', storageId)}`,
      { responseType: 'blob' }
    );
  }

  getPreview(transactionId: string, documentId: string) {
    return this._http.get(
      `${apiEndpoints.getPreview
        .replace('[[transactionId]]', transactionId)
        .replace('[[documentId]]', documentId)}`,
      { responseType: 'blob' }
    );
  }

  sendOTP(body: any) {
    return this._http.post(`${apiEndpoints.sendTransactionOTP}`, body);
  }

  confirmOTP(body: any) {
    return this._http.post(`${apiEndpoints.confirmTransactionOTP}`, body);
  }

  selfSignOTP(body: any) {
    return this._http.post(`${apiEndpoints.selfSignTransactionOTP}`, body);
  }

  getMyAction(id: string) {
    return this._http.get<any>(
      `${apiEndpoints.getMyAction.replace('[[txnId]]', id)}`
    );
  }

  confirmOTPAndSelfSign(body: any) {
    return this._http.post(
      `${apiEndpoints.confirmOTPAndSelfSignTransaction}`,
      body
    );
  }

  sendOffer(body: any) {
    return this._http.post(`${apiEndpoints.sendOfferTransaction}`, body);
  }

  sendTransaction(body: any, transactionId: string) {
    return this._http.patch(
      `${apiEndpoints.sendTransaction.replace(
        '[[transactionId]]',
        transactionId
      )}`,
      body
    );
  }

  signUsingAadhar(body: any) {
    return this._http.post(`${apiEndpoints.signUsingAadharTransaction}`, body);
  }

  getThumbnail(transactionId: string, documentId: string): any {
    return this._http.get(
      `${apiEndpoints.getThumbnail
        .replace('[[transactionId]]', transactionId)
        .replace('[[documentId]]', documentId)}`,
      { responseType: 'blob' }
    );
  }

  getAllParticipant(transactionId: string) {
    return this._http.get<any>(
      `${apiEndpoints.getAllTransactionParticipant.replace(
        '[[transactionId]]',
        transactionId
      )}`
    );
  }

  getUsersByCompanyId(companyId: string, query: string) {
    return this._http.get<any>(
      `${apiEndpoints.getUsersByCompanyId
        .replace('[[companyId]]', companyId
        ).replace('[[query]]', query)}`
    );
  }

  getParticipantDetail(transactionId: string, participantId: string) {
    return this._http.get<any>(
      `${apiEndpoints.getTransactionParticipantDetail
        .replace('[[transactionId]]', transactionId)
        .replace('[[participantId]]', participantId)}`
    );
  }

  getTransactionStatusById(transactionId: string, participantId?: string) {
    return this._http.get<any>(
      `${apiEndpoints.getTransactionStatusById
        .replace('[[transactionId]]', transactionId)
        .replace('[[participantId]]', participantId ? participantId : '')
      }`
    );
  }

  updateTransistStatus(transactionId: string, body: any) {
    return this._http.patch<any>(
      `${apiEndpoints.getTransitById
        .replace('[[transactionId]]', transactionId)
      }`, body
    );
  }

  updateParticipant(body: any, transactionId: string, participantId: string) {
    return this._http.put<any>(
      `${apiEndpoints.updateTransactionParticipant
        .replace('[[transactionId]]', transactionId)
        .replace('[[participantId]]', participantId)}`,
      body
    );
  }
}
