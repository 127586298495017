import { environment } from '../../../environments/environment';

export const apiEndpoints = {
  getLoginUserInfo: environment.API_BASE_URL + 'user/who-am-i',
  getBusinessProcessTemplate: environment.API_BASE_URL + 'v2/companies/',
  getUICustomization: environment.API_BASE_URL + 'companies/',
  getRecentTransaction: environment.API_BASE_URL + 'transactions',
  addTransaction: environment.API_BASE_URL + 'transactions',
  getTransactionDocumentsDetails:
    environment.API_BASE_URL + '/transactions/[[txnId]]/documents?participantId=[[participantId]]',
  uploadDocumentInTransaction:
    environment.API_BASE_URL + '/transactions/[[txnId]]/documents/upload',
  getFile: environment.API_BASE_URL + 'content/show-object/[[storageId]]',
  getPreview:
    environment.API_BASE_URL +
    'transactions/[[transactionId]]/documents/[[documentId]]/preview',
  createTemplate:
    environment.API_BASE_URL + '/v2/companies/[[companyId]]/templates',
  getTemplate: environment.API_BASE_URL + 'v2/templates/[[templateId]]',
  updateTemplate: environment.API_BASE_URL + 'v2/templates/[[templateId]]',
  sendTransactionOTP: environment.API_BASE_URL + 'sa/send-otp',
  confirmTransactionOTP: environment.API_BASE_URL + 'sa/confirm-otp',
  selfSignTransactionOTP: environment.API_BASE_URL + 'hr/self-sign-documents',
  getMyAction: environment.API_BASE_URL + '/transactions/[[txnId]]/my-actions',
  getOrAddDocumentTypesOfTemplate:
    environment.API_BASE_URL + 'v2/templates/[[templateId]]/document-types',
  addParticipantInTemplate:
    environment.API_BASE_URL + 'v2/templates/[[templateId]]/participants',
  updateParticipantInTemplate:
    environment.API_BASE_URL + 'v2/templates/[[templateId]]/participants/[[participantId]]',
  addSignaturePositionInOperation:
    environment.API_BASE_URL +
    'v2/document-types/[[documentTypeId]]/sign-positions',
  getSignaturePositionInOperation:
    environment.API_BASE_URL +
    'v2/document-types/[[documentTypeId]]/sign-positions?partyType=[[getPartyType]]',
  updateSignaturePositionInOperation:
    environment.API_BASE_URL +
    'v2/document-types/[[documentTypeId]]/sign-positions/[[signaturePositionId]]',
  getParticipantOperationByDocumentTypeAndOperationId:
    environment.API_BASE_URL +
    'v2/document-types/[[documentTypeId]]/participant-operations/[[operationId]]',
  saveParticipantOperationsByDocumentTypeAndParticipantId:
    environment.API_BASE_URL +
    'v2/document-types/[[documentTypeId]]/participant-operations',
  updateDocumentTypeOfTemplate:
    environment.API_BASE_URL + 'v2/document-types/[[documentTypeId]]',
  getAllParticipantsOfTemplate:
    environment.API_BASE_URL + 'v2/templates/[[templateId]]/participants',
  getAllSignPositionOfDocumentType:
    environment.API_BASE_URL +
    'v2/document-types/[[documentTypeId]]/sign-positions2',
  getDocumentThumbnail:
    environment.API_BASE_URL +
    'v2/document-types/[[documentTypeId]]/base-document-thumbnail',
  deleteDocumentTypeOfTemplate:
    environment.API_BASE_URL + 'v2/document-types/[[documentTypeId]]',
  deleteParticipantOfTemplate:
    environment.API_BASE_URL +
    'v2/templates/[[templateId]]/participants/[[participantId]]',
  getDocumentForPreview:
    environment.API_BASE_URL +
    'v2/document-types/[[documentTypeId]]/base-document',
  getParticipantsOperationsBasedOnDocumentType:
    environment.API_BASE_URL +
    'v2/document-types/[[documentTypeId]]/participant-operations',
  confirmOTPAndSelfSignTransaction:
    environment.API_BASE_URL + 'hr/self-sign-documents-otp',
  sendOfferTransaction: environment.API_BASE_URL + 'hr/sendOffer',
  signUsingAadharTransaction: environment.API_BASE_URL + 'hr/sign-documents',
  getTransactionDetails:
    environment.API_BASE_URL + 'transactions/[[transactionId]]',
  sendTransaction:
    environment.API_BASE_URL + 'transactions/[[transactionId]]/send',
  getThumbnail:
    environment.API_BASE_URL +
    'transactions/[[transactionId]]/documents/[[documentId]]/thumbnail',
  getAllTransactionParticipant:
    environment.API_BASE_URL + 'transactions/[[transactionId]]/participants',
  getTransactionParticipantDetail:
    environment.API_BASE_URL +
    'transactions/[[transactionId]]/participants/[[participantId]]',
  updateTransactionParticipant:
    environment.API_BASE_URL +
    'transactions/[[transactionId]]/participants/[[participantId]]',
  getTemplateByCompanyIdAndTemplateId:
    environment.API_BASE_URL +
    'companies/[[companyId]]/templates/[[templateId]]',
  getTransactionStatusById: environment.API_BASE_URL + '/transactions/[[transactionId]]/status-transitions?future=true&participantId=[[participantId]]',
  getTransitById: environment.API_BASE_URL + '/transactions/[[transactionId]]/transit-status',
  getUsersByCompanyId: environment.API_BASE_URL +
    'v2/companies/[[companyId]]/users?q=[[query]]',
};
