import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeChangeService {
  private _darkTheme = new BehaviorSubject<boolean>(false);
  private _darkTheme$ = this._darkTheme.asObservable();

  isDarkTheme(): Observable<boolean> {
    return this._darkTheme$;
  }

  setDarkTheme(isDarkTheme: boolean): void {
    this._darkTheme.next(isDarkTheme);
  }
}
