import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { apiEndpoints } from '../utility/api-endpoints';

@Injectable({
  providedIn: 'root',
})
export class EsignService {
  constructor(private _http: HttpClient, private _router: Router) { }

  public getBusinessProcessTemplate(
    companyId: number,
    queryParams: any
  ): Observable<any> {
    let fillEnabled = false;
    if (queryParams?.fillEnabled == true || queryParams?.fillEnabled == false) {
      fillEnabled = queryParams?.fillEnabled;
    }
    let bulkEnabled = false;
    if (queryParams?.bulkEnabled == true || queryParams?.bulkEnabled == false) {
      bulkEnabled = queryParams?.bulkEnabled;
    }
    let params = new HttpParams();
    params = params.append(
      'templateType',
      queryParams?.templateType ? queryParams?.templateType : ''
    );
    params = params.append('fillEnabled', fillEnabled);
    params = params.append('bulkEnabled', bulkEnabled);
    params = params.append(
      'page',
      queryParams?.page >= 0 ? queryParams?.page : 0
    );
    params = params.append(
      'size',
      queryParams?.size > 0 ? queryParams?.size : 15
    );
    params = params.append(
      'sort',
      queryParams?.sort ? queryParams?.sort : 'id,desc'
    );
    params = params.append(
      'search',
      queryParams?.search ? queryParams?.search : ''
    );
    params = params.append(
      'state',
      queryParams?.state ? queryParams?.state : ''
    );
    let url: string =
      apiEndpoints.getBusinessProcessTemplate + companyId + '/templates';
    return this._http.get<any>(url, { params: params }).pipe(
      map((res) => {
        if (res) {
          return res;
        }
        throw 'Invalid Data.';
      })
    );
  }

  public getUICustomization(
    companyId: number,
    queryParams: any
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append(
      'templateId',
      queryParams?.templateId ? queryParams?.templateId : ''
    );
    params = params.append(
      'section',
      queryParams?.section ? queryParams?.section : 'transactions'
    );
    params = params.append(
      'componentType',
      queryParams?.componentType ? queryParams?.componentType : 'field'
    );
    params = params.append(
      'userType',
      queryParams?.userType ? queryParams?.userType : 'first_party'
    );
    params = params.append(
      'uiType',
      queryParams?.uiType ? queryParams?.uiType : 'desktop'
    );

    let url: string =
      apiEndpoints.getUICustomization + companyId + '/ui-customizations';
    return this._http.get<any>(url, { params: params }).pipe(
      map((res) => {
        if (res) {
          return res;
        }
        throw 'Invalid Data.';
      })
    );
  }

  public getCompanySpecificUICustomization(
    companyId: number,
    queryParams: any
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append(
      'templateId',
      queryParams?.templateId ? queryParams?.templateId : ''
    );
    params = params.append(
      'section',
      queryParams?.section ? queryParams?.section : 'admin_company_specific_style'
    );
    params = params.append(
      'componentType',
      queryParams?.componentType ? queryParams?.componentType : 'field'
    );
    params = params.append(
      'userType',
      queryParams?.userType ? queryParams?.userType : 'first_party'
    );

    let url: string =
      apiEndpoints.getUICustomization + companyId + '/ui-customizations';
    return this._http.get<any>(url, { params: params }).pipe(
      map((res) => {
        if (res) {
          return res;
        }
        throw 'Invalid Data.';
      })
    );
  }

  public getRecentTransaction(queryParams: any): Observable<any> {
    let params = new HttpParams();
    params = params.append(
      'page',
      queryParams?.page >= 0 ? queryParams?.page : 0
    );
    params = params.append(
      'size',
      queryParams?.size > 0 ? queryParams?.size : 10
    );
    params = params.append(
      'sort',
      queryParams?.sort ? queryParams?.sort : 'id,desc'
    );
    params = params.append(
      'view',
      queryParams?.view ? queryParams?.view : 'MY_TXNS'
    );

    return this._http
      .get<any>(apiEndpoints.getRecentTransaction, { params: params })
      .pipe(
        map((res) => {
          if (res) {
            return res;
          }
          throw 'Invalid Data.';
        })
      );
  }
}
