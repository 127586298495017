import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pdf-preview-popup',
  templateUrl: './pdf-preview-popup.component.html',
  styleUrls: ['./pdf-preview-popup.component.scss'],
})
export class PdfPreviewPopupComponent implements OnInit {
  page: number = 1;
  totalPages: number = 1;
  isLoaded: boolean = false;
  file: any;
  name: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    if (this.data.file) {
      this.file = this.data.file;
    }
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }

  nextPage() {
    if (this.page !== this.totalPages) {
      this.page++;
    }
  }

  prevPage() {
    if (this.page !== 1) {
      this.page--;
    }
  }
}
