export function copy(args: any) {
  return JSON.parse(JSON.stringify(args));
}

export function arrayBufferToPDFBlob(arrayBuffer: any) {
  const arr = new Uint8Array(arrayBuffer);
  const blob = new Blob([arr], { type: 'application/pdf' });
  return URL.createObjectURL(blob);
}

export function hideEmail(email: string) {
  var hiddenEmail = '';
  if (email) {
    var temp = email; //anas.behhari@gmail.com
    for (let i = 0; i < temp.length; i++) {
      if (i > 1 && i < temp.indexOf('@')) {
        hiddenEmail += '*';
      } else {
        hiddenEmail += temp[i];
      }
    }
    // console.log(hiddenEmail); //an.*******@gmail.com
  }
  return hiddenEmail;
}
