<h1 mat-dialog-title>
  {{ title }}
</h1>
<div mat-dialog-content>
  <p [innerHTML]="message"></p>
</div>
<div mat-dialog-actions>
  <button (click)="onConfirm()" color="primary" mat-flat-button>
    {{ confirmButtonText }}
  </button>
  <button (click)="onDismiss()" color="accent" mat-flat-button>
    {{ cancelButtonText }}
  </button>
</div>
