<div *ngIf="scrWidth > 770" class="relative-modal">
  <div class="popup-Awaiting">
    <div class="close-icon">
      <a mat-dialog-close>
        <img class="cursor-pointer margin-left-auto" src="assets/icons/Close_icon.svg" />
      </a>
    </div>
    <div class="awaiting-esign">Awaiting Esign</div>
    <div class="new-esign-request text-center">
      New eSign request
    </div>
    <div class="tab-panel-list">
      <div class="ellipse dotted-after" *ngFor="let participant of participants">
        <img class="cursor-pointer" *ngIf="participant.status == 'ACCEPTED'"
          src="assets/icons/check_circle_white.svg" />
        <img class="cursor-pointer" *ngIf="participant.status != 'ACCEPTED'" src="assets/icons/circle_white.svg" />
        <p class="user-info">{{participant.participantName}}</p>
        <!-- <p class="date-info">Signed</p> -->
      </div>
      <!-- <div class="ellipse dotted-after">
        <img class="cursor-pointer" src="assets/icons/circle_white.svg" />
        <p class="user-info">Rishi</p>
        <p class="date-info">Sign Awaiting</p>
      </div> -->
      <!-- <div class="ellipse dotted-after">
        <img class="cursor-pointer" src="assets/icons/circle_white.svg" />
        <p class="user-info">Sindhu</p>
        <p class="date-info">Sign Awaiting</p>
      </div> -->
      <div class="ellipse ellipse-15">
        <img class="cursor-pointer" src="assets/icons/circle_white.svg" />
        <p class="user-info">Process Done</p>
      </div>
    </div>
  </div>
</div>

<div *ngIf="scrWidth <= 770" class="relative-modal">
  <div class="popup-Awaiting">
    <div class="close-icon">
      <a mat-dialog-close>
        <img class="cursor-pointer margin-left-auto" src="assets/icons/Close_icon.svg" />
      </a>
    </div>
    <div class="awaiting-esign">Awaiting Esign</div>
    <div class="new-esign-request">
      New eSign request is sent to Rishi and Sindhu to sign.
    </div>
    <div class="tab-panel-list-mobile">
      <div class="ellipse dotted-after">
        <img class="cursor-pointer" src="assets/icons/check_circle_white.svg" />
        <p class="user-info">Suresh</p>
        <p class="date-info">Signed on Sun, 26 Jan '22</p>
      </div>
      <div class="ellipse dotted-after">
        <img class="cursor-pointer" src="assets/icons/circle_white.svg" />
        <p class="user-info">Rishi</p>
        <p class="date-info">Sign Awaiting</p>
      </div>
      <div class="ellipse dotted-after">
        <img class="cursor-pointer" src="assets/icons/circle_white.svg" />
        <p class="user-info">Sindhu</p>
        <p class="date-info">Sign Awaiting</p>
      </div>
      <div class="ellipse ellipse-15">
        <img class="cursor-pointer" src="assets/icons/circle_white.svg" />
        <p class="user-info">Awaiting Signed Document</p>
      </div>
    </div>
  </div>
</div>