<mat-card class="mb-3">
  <mat-card-content>
    <div class="card-content">
      <div class="d-flex ps-4 ps-xs-0">
        <div class="relative">
          <img (click)="changeStep(1)" *ngIf="currentStep == 1" class="step_icon"
            src="assets/icons/add_document_icon.svg" />
          <img (click)="changeStep(1)" *ngIf="currentStep == 2" class="step_icon step_icon00"
            src="assets/icons/add document_icon_green.svg" />
          <img (click)="changeStep(1)" *ngIf="currentStep == 3" class="step_icon step_icon00"
            src="assets/icons/add document_icon_green.svg" />
          <span class="step-label step-label-enable">Add Document</span>
        </div>
        <div *ngIf="currentStep == 1" class="horizontal-connected-line"></div>
        <div *ngIf="currentStep == 2" class="horizontal-connected-line horizontal-connected-line00"></div>
        <div *ngIf="currentStep == 3" class="horizontal-connected-line horizontal-connected-line00"></div>
        <div class="relative">
          <img (click)="changeStep(2)" *ngIf="currentStep == 1" class="step_icon"
            src="assets/icons/add_signatory_icon.svg" />
          <img (click)="changeStep(2)" *ngIf="currentStep == 2" class="step_icon"
            src="assets/images/Add-signatory_icon.svg" />
          <img (click)="changeStep(2)" *ngIf="currentStep == 3" class="step_icon step_icon00"
            src="assets/icons/add document_icon_green.svg" />
          <span *ngIf="currentStep == 1" class="step-label step-label-disable">Add Signatory</span>
          <span *ngIf="currentStep == 2" class="step-label step-label-enable">Add Signatory</span>
          <span *ngIf="currentStep == 3" class="step-label step-label-enable">Add Signatory</span>
        </div>
        <div *ngIf="currentStep == 1" class="horizontal-connected-line"></div>
        <div *ngIf="currentStep == 2" class="horizontal-connected-line"></div>
        <div *ngIf="currentStep == 3" class="horizontal-connected-line horizontal-connected-line00"></div>
        <div class="relative">
          <img (click)="changeStep(3)" *ngIf="currentStep == 1" class="step_icon" src="assets/icons/publish_icon.svg" />
          <img (click)="changeStep(3)" *ngIf="currentStep == 2" class="step_icon" src="assets/icons/publish_icon.svg" />
          <img (click)="changeStep(3)" *ngIf="currentStep == 3" class="step_icon"
            src="assets/images/Publish_icon.svg" />
          <span *ngIf="currentStep == 1" class="step-label last-step-label step-label-disable">Submit</span>
          <span *ngIf="currentStep == 2" class="step-label last-step-label step-label-disable">Submit</span>
          <span *ngIf="currentStep == 3" class="step-label last-step-label step-label-enable">Submit</span>
        </div>
      </div>
    </div>
  </mat-card-content>
  <button (click)="changeSize()" *ngIf="currentFluid" class="toggle-button">
    <img [src]="
        !sizePannel
          ? 'assets/images/Group 1536 (1).svg'
          : 'assets/images/Group-1536.svg'
      " />
  </button>
</mat-card>