import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiEndpoints } from '../utility/api-endpoints';
import { Observable, delay, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TemplatesService {
  constructor(private _http: HttpClient) { }

  createTemplate(values: any, companyId: string) {
    return this._http.post<any>(
      `${apiEndpoints.createTemplate.replace('[[companyId]]', companyId)}`,
      values
    );
  }

  getTemplate(id: string) {
    return this._http.get<any>(
      `${apiEndpoints.getTemplate.replace('[[templateId]]', id)}`
    );
  }

  getTemplateByCompanyIdAndTemplateId(companyId: string, templateId: string) {
    return this._http.get<any>(
      `${apiEndpoints.getTemplateByCompanyIdAndTemplateId
        .replace('[[companyId]]', companyId)
        .replace('[[templateId]]', templateId)}`
    );
  }

  updateTemplate(templateId: string, values: any) {
    return this._http.put(
      `${apiEndpoints.updateTemplate.replace('[[templateId]]', templateId)}`,
      values
    );
  }

  patchTemplate(templateId: string, values: any) {
    return this._http.patch(
      `${apiEndpoints.updateTemplate.replace('[[templateId]]', templateId)}`,
      values
    );
  }

  getAllDocumentTypesOfTemplate(id: string) {
    return this._http.get<any>(
      `${apiEndpoints.getOrAddDocumentTypesOfTemplate.replace(
        '[[templateId]]',
        id
      )}`
    );
  }

  getAllParticipantsOfTemplate(templateId: string) {
    return this._http.get<any>(
      `${apiEndpoints.getAllParticipantsOfTemplate.replace(
        '[[templateId]]',
        templateId
      )}`
    );
  }

  getAllSignPositionOfDocumentType(documentTypeId: string) {
    return this._http.get<any>(
      `${apiEndpoints.getAllSignPositionOfDocumentType.replace(
        '[[documentTypeId]]',
        documentTypeId
      )}`
    );
  }

  getDocumentThumbnail(documentTypeId: string) {
    return this._http.get(
      `${apiEndpoints.getDocumentThumbnail.replace(
        '[[documentTypeId]]',
        documentTypeId
      )}`,
      { responseType: 'blob' }
    );
  }

  addDocumentTypeInTemplate(id: string, values: any) {
    return this._http.post<any>(
      `${apiEndpoints.getOrAddDocumentTypesOfTemplate.replace(
        '[[templateId]]',
        id
      )}`,
      values
    );
  }

  addParticipantInTemplate(id: string, values: any) {
    return this._http.post<any>(
      `${apiEndpoints.addParticipantInTemplate.replace('[[templateId]]', id)}`,
      values
    );
  }

  updateParticipantInTemplate(id: string, participantId: any, values: any) {
    return this._http.put<any>(
      `${apiEndpoints.updateParticipantInTemplate.replace('[[templateId]]', id).replace('[[participantId]]', participantId)}`,
      values
    );
  }

  async addSignaturePositionInOperation(documentTypeId: string, values: any) {
    return await this._http.post<any>(
      `${apiEndpoints.addSignaturePositionInOperation.replace(
        '[[documentTypeId]]',
        documentTypeId
      )}`,
      values
    );
  }

  getSignaturePositionInOperation(documentTypeId: string, partyType: any) {
    return this._http.get<any>(
      `${apiEndpoints.addSignaturePositionInOperation
        .replace('[[documentTypeId]]', documentTypeId)
        .replace('[[getPartyType]]', partyType)
      }`,
    );
  }

  updateSignaturePositionInOperation(
    documentTypeId: string,
    signaturePositionId: string,
    values: any
  ) {
    return this._http.put<any>(
      `${apiEndpoints.updateSignaturePositionInOperation
        .replace('[[documentTypeId]]', documentTypeId)
        .replace('[[signaturePositionId]]', signaturePositionId)}`,
      values
    );
  }

  getParticipantOperationByDocumentTypeAndOperationId(
    documentTypeId: string,
    operationId: string
  ) {
    return this._http.get<any>(
      `${apiEndpoints.getParticipantOperationByDocumentTypeAndOperationId
        .replace('[[documentTypeId]]', documentTypeId)
        .replace('[[operationId]]', operationId)}`
    );
  }

  saveParticipantOperationsByDocumentTypeAndParticipantId(
    documentTypeId: string,
    values: any
  ) {
    return this._http.post<any>(
      `${apiEndpoints.saveParticipantOperationsByDocumentTypeAndParticipantId.replace(
        '[[documentTypeId]]',
        documentTypeId
      )}`,
      values
    );
  }

  updateParticipantOperationsByDocumentTypeAndParticipantId(
    documentTypeId: string,
    operationId: string,
    values: any
  ) {
    return this._http.put<any>(
      `${apiEndpoints.getParticipantOperationByDocumentTypeAndOperationId
        .replace('[[documentTypeId]]', documentTypeId)
        .replace('[[operationId]]', operationId)}`,
      values
    );
  }

  updateDocumentType(documentTypeId: string, values: any) {
    return this._http.put<any>(
      `${apiEndpoints.updateDocumentTypeOfTemplate.replace(
        '[[documentTypeId]]',
        documentTypeId
      )}`,
      values
    );
  }

  deleteDocumentType(documentTypeId: string) {
    return this._http.delete(
      `${apiEndpoints.deleteDocumentTypeOfTemplate.replace(
        '[[documentTypeId]]',
        documentTypeId
      )}`
    );
  }

  deleteParticipantOfTemplate(templateId: string, participantId: string) {
    return this._http.delete(
      `${apiEndpoints.deleteParticipantOfTemplate
        .replace('[[templateId]]', templateId)
        .replace('[[participantId]]', participantId)}`
    );
  }

  getDocumentForPreview(documentTypeId: string) {
    return this._http.get(
      `${apiEndpoints.getDocumentForPreview.replace(
        '[[documentTypeId]]',
        documentTypeId
      )}`,
      { responseType: 'blob' }
    );
  }

  getParticipantsOperationsBasedOnDocumentType(documentTypeId: string) {
    return this._http.get<any[]>(
      `${apiEndpoints.getParticipantsOperationsBasedOnDocumentType.replace(
        '[[documentTypeId]]',
        documentTypeId
      )}`
    );
  }
}
