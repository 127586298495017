import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EsignService } from './esign.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class TemplatesFilterService {
  currentUser: any = null;
  private _templates = new BehaviorSubject<any>([]);
  private _templates$ = this._templates.asObservable();
  private _templateTotal = new BehaviorSubject<any>(0);
  private _templatesTotal$ = this._templateTotal.asObservable();

  constructor(
    private esignService: EsignService,
    private authService: AuthService
  ) {}

  getTemplatesValue(): Observable<any> {
    return this._templates$;
  }

  getTemplatesTotal(): Observable<any> {
    return this._templatesTotal$;
  }

  setTemplates(params: any): void {
    this.currentUser = this.authService.currentUserValue;
    this.esignService
      .getBusinessProcessTemplate(this.currentUser.companyId, params)
      .subscribe((response: any) => {
        if (response.totalElements) {
          this._templateTotal.next(response.totalElements);
        }
        if (response.content) {
          this._templates.next(response.content);
        }
      });
  }
}
