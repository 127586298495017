import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';
import { Router } from '@angular/router';
import { Constants } from '../../shared/utility/constants';
import { environment } from '../../../environments/environment';
import { ToastService } from '../../shared/services/toast.service';
import { ToastType } from 'src/app/shared/components/toast-dialog/toast-dialog.component';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private toast: ToastService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token: any = localStorage.getItem(Constants.tokenStorageName);
    const isApiUrl = request.url.startsWith(environment.API_BASE_URL);
    if (token && isApiUrl) {
      request = request.clone({
        setHeaders: {
          'X-Authorization': `${token}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 400) {
          if (err && err.error && err.error.error) {
            this.toast.openToast(err.error.error, ToastType.error);
          }
        } else if (err.status === 401 || err.status === 403) {
          // except 401 and 5XX need to show error
          this.toast.openToast(
            'Unauthorized user. Please login again.',
            ToastType.error
          );
          this._authService.logout(this._router.url);
        } else if (err.status === 404) {
        } else {
          this.toast.openToast(
            'There is something wrong. Please try again later.',
            ToastType.error
          );
        }
        throw err;
      })
    );
  }
}
