import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { NglpScrollDirective } from './directives/nglp-scroll.directive';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { PdfPreviewPopupComponent } from './components/pdf-preview-popup/pdf-preview-popup.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TextFieldModule } from '@angular/cdk/text-field';
import { ToastDialogComponent } from './components/toast-dialog/toast-dialog.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { ShowDetailsPopupComponent } from './components/show-details-popup/show-details-popup.component';
import { TransactionDetailsPopupComponent } from './components/transaction-details-popup/transaction-details-popup.component';
import { VerifyOtpPopupComponent } from './components/verify-otp-popup/verify-otp-popup.component';
import { MatInputModule } from '@angular/material/input';
import { StepsUiComponent } from './components/steps-ui/steps-ui.component';
import { MatCardModule } from '@angular/material/card';
import { CreateTemplatePopupComponent } from './components/create-template-popup/create-template-popup.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PreviewPopupComponent } from './components/preview-popup/preview-popup.component';
import { PreviewPdfWithSignaturesComponent } from './components/preview-pdf-with-signatures/preview-pdf-with-signatures.component';
import { OutsideClickDirective } from './directives/outside-click.directive';
import { TruncatePipe } from './pipes/truncate.pipe';
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  declarations: [
    HeaderComponent,
    NglpScrollDirective,
    ConfirmDialogComponent,
    PdfPreviewPopupComponent,
    ToastDialogComponent,
    DeleteDialogComponent,
    ShowDetailsPopupComponent,
    TransactionDetailsPopupComponent,
    VerifyOtpPopupComponent,
    StepsUiComponent,
    CreateTemplatePopupComponent,
    PreviewPopupComponent,
    PreviewPdfWithSignaturesComponent,
    OutsideClickDirective,
    TruncatePipe,
  ],
  exports: [
    HeaderComponent,
    NglpScrollDirective,
    ConfirmDialogComponent,
    ToastDialogComponent,
    DeleteDialogComponent,
    StepsUiComponent,
    OutsideClickDirective,
    TruncatePipe
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    PdfViewerModule,
    MatMenuModule,
    MatSlideToggleModule,
    TextFieldModule,
    MatInputModule,
    MatCardModule,
    ReactiveFormsModule,
    NgxSpinnerModule
  ],
})
export class SharedModule { }
