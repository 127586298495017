<div class="relative-modal">
  <div class="paragraph content" mat-dialog-content>
    <div class="row">
      <div class="col-md-8">
        <div [formGroup]="otpForm" class="popup-header">
          <h4>Enter the code</h4>
          <p>
            Enter the 6-digit verification code to confirm you got the text
            message
          </p>
          <div class="email-content">
            <label>Email</label>
            <p>{{ data?.hideEmail }}</p>
            <!-- <p>s* * * * * * * * * * *.com</p> -->
          </div>
          <div class="email-content">
            <label>OTP</label>
            <div class="otp-input">
              <input
                (keyup)="keytab($event, 1)"
                formControlName="otp1"
                matInput
                maxlength="1"
                onkeyup="this.value=this.value.replace(/[^\d]/,'')"
                size="1"
                type="text"
              />
              <input
                (keyup)="keytab($event, 1)"
                formControlName="otp2"
                matInput
                maxlength="1"
                onkeyup="this.value=this.value.replace(/[^\d]/,'')"
                size="1"
                type="text"
              />
              <input
                (keyup)="keytab($event, 1)"
                formControlName="otp3"
                matInput
                maxlength="1"
                onkeyup="this.value=this.value.replace(/[^\d]/,'')"
                size="1"
                type="text"
              />
              <input
                (keyup)="keytab($event, 1)"
                formControlName="otp4"
                matInput
                maxlength="1"
                onkeyup="this.value=this.value.replace(/[^\d]/,'')"
                size="1"
                type="text"
              />
              <input
                (keyup)="keytab($event, 1)"
                formControlName="otp5"
                matInput
                maxlength="1"
                onkeyup="this.value=this.value.replace(/[^\d]/,'')"
                size="1"
                type="text"
              />
              <input
                (keyup)="keytab($event, 1)"
                formControlName="otp6"
                matInput
                maxlength="1"
                onkeyup="this.value=this.value.replace(/[^\d]/,'')"
                size="1"
                type="text"
              />
            </div>
          </div>
          <div class="d-flex resend-verify">
            <button
              (click)="verifyOtp()"
              [class.next-enable]="!otpForm.invalid"
              [disabled]="otpForm.invalid"
              class="verify-button"
            >
              Verify
            </button>
            <p (click)="resendOtp()" class="Resend-OTP cursor-pointer">
              Resend OTP
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="locker-pop">
          <img
            aria-colspan="img-fluid"
            class="svg-opacity"
            src="assets/images/Group 121.svg"
          />
        </div>
      </div>
    </div>
  </div>
</div>
