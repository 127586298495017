import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-show-details-popup',
  templateUrl: './show-details-popup.component.html',
  styleUrls: ['./show-details-popup.component.scss'],
})
export class ShowDetailsPopupComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    // console.log(data);
  }

  ngOnInit(): void {}
}
