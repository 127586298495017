import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export class PreviewPopupData {
  documents: any;
  isViewDropdown: boolean = true;
}

@Component({
  selector: 'app-preview-popup',
  templateUrl: './preview-popup.component.html',
  styleUrls: ['./preview-popup.component.scss'],
})
export class PreviewPopupComponent implements OnInit {
  page: number = 1;
  totalPages: number = 1;
  isLoaded: boolean = false;
  selectedDocument: any = null;
  totalPagesArray: any[] = [];
  currentWidth: number = 0;
  currentHight: number = 0;
  isViewDropdown: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: PreviewPopupData) {}

  ngOnInit(): void {
    // console.log(this.data);
    this.initPdf();
    this.currentWidth = window.innerWidth;
    this.currentHight = window.innerHeight;
    // console.log(
    //   'Width = ' + this.currentWidth + ' ' + 'Hight = ' + this.currentHight
    // );
  }

  initPdf() {
    this.isViewDropdown = this.data.isViewDropdown == false ? false : true;
    this.selectedDocument = this.data.documents[0];
    this.isLoaded = false;
    this.page = 1;
    this.totalPages = 1;
    this.totalPagesArray = [];
    this.isLoaded = true;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event: any) {
    this.currentHight = window.innerHeight;
    this.currentWidth = window.innerWidth;
  }

  changePDF(data: any) {
    this.isLoaded = false;
    this.page = 1;
    if (data.documentId != this.selectedDocument.documentId) {
      this.totalPages = 1;
      this.totalPagesArray = [];
      this.selectedDocument = data;
    }
    this.isLoaded = true;
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.totalPagesArray = Array.from(
      { length: this.totalPages },
      (_, i) => i + 1
    );
    this.isLoaded = true;
  }

  nextPage() {
    if (this.page !== this.totalPages) {
      this.page++;
    }
  }

  prevPage() {
    if (this.page !== 1) {
      this.page--;
    }
  }

  checkSignAvailable(providedIndex: number) {
    let flag = false;
    let index = 0;
    for (const item of this.selectedDocument.signatureLocations) {
      if (providedIndex == index) {
        if (item.page == -1) {
          if (this.page == this.totalPages) {
            flag = true;
            break;
          }
        } else if (item.page == 0) {
          flag = true;
        } else {
          if (item.page == this.page) {
            flag = true;
            break;
          }
        }
      }
      index++;
    }
    return flag;
  }
}
