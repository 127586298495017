import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogModel } from '../../../data/confirm-dialog-modal';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  title: string;
  message: string;
  confirmButtonText: string = 'Yes';
  cancelButtonText: string = 'No';

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
  ) {
    this.title = data.title;
    this.message = data.message;
    if (data.confirmButtonText) {
      this.confirmButtonText = data.confirmButtonText;
    }
    if (data.cancelButtonText) {
      this.cancelButtonText = data.cancelButtonText;
    }
  }

  ngOnInit(): void {}

  onDismiss() {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  onConfirm() {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }
}
