<div class="popup-template">
  <div class="row">
    <div class="col-md-6"></div>
    <div class="col-md-6 popup-title">
      <img alt="" class="me-3" src="assets/images/file-icon.svg" />
      <ng-container *ngFor="let customData of companySpecificUiCustomData.slice(2,3)">
        <ng-container
          *ngIf="customData?.internalName == '_createProcessTemplate_' && customData?.stateOrder == 3 && customData?.displayInUi == true">
          <h2 mat-dialog-title>{{ customData?.displayName}}</h2>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="companySpecificUiCustomData.slice(2,3).length == 0">
        <h2 mat-dialog-title>{{'Create Business Template'}}</h2>
      </ng-container>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 d-flex justify-content-center">
      <img class="img-fluid" src="assets/images/Group 3243.svg" />
    </div>
    <div class="col-md-6 pt-4 pb-1">
      <div class="fix-input">
        <form [formGroup]="createTemplateForm">
          <mat-form-field appearance="fill" class="template-name-input">

            <ng-container *ngFor="let customData of companySpecificUiCustomData.slice(3,4);">
              <ng-container
                *ngIf="customData?.internalName == '_createTemplateName_' &&  customData?.stateOrder == 4 && customData?.displayInUi == true">
                <mat-label>{{ customData?.displayName}}</mat-label>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="companySpecificUiCustomData.slice(3,4).length == 0">
              <mat-label>{{'Template name'}}</mat-label>
            </ng-container>
            <input formControlName="name" matInput type="text" />
          </mat-form-field>
          <ng-container *ngFor="let customData of companySpecificUiCustomData.slice(4,5);">
            <ng-container
              *ngIf="customData?.internalName == '_createTemplateDescription_'  &&  customData?.stateOrder == 5 && customData?.displayInUi == true">
              <mat-form-field appearance="outline" class="template-des-input" floatLabel="never">
                <textarea class="template-des-input-textarea" formControlName="description" matInput
                  placeholder="{{ customData?.displayName }}" rows="7"></textarea>
              </mat-form-field>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="companySpecificUiCustomData.slice(4,5).length == 0">
            <mat-form-field appearance="outline" class="template-des-input" floatLabel="never">
              <textarea class="template-des-input-textarea" formControlName="description" matInput
                placeholder="Add Message description" rows="7"></textarea>
            </mat-form-field>
          </ng-container>
        </form>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6"></div>
    <div class="col-md-6">
      <button (click)="createTemplate()" [disabled]="!this.currentUser && createTemplateForm.invalid" class="btn-create"
        color="primary" mat-flat-button>
        Create
      </button>
      <button [mat-dialog-close]="false" color="accent" mat-flat-button>
        Cancel
      </button>
    </div>
  </div>
</div>